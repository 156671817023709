import { message } from "antd";

export const SuccessMessage = (messages) => {
    message.success({
        content: messages,
        duration: 3,
        style: {
            position: 'fixed',
            bottom: '5vh',
            right: '5em'
        }
    });
}
export const ErrorMessage = (messages) => {
    message.error({
        content: messages,
        duration: 3,
        style: {
            position: 'fixed',
            bottom: '5vh',
            right: '5em'
        }
    });
}
export const WarningMessage = (messages) => {
    message.warning({
        content: messages,
        duration: 3,
        style: {
            position: 'fixed',
            bottom: '5vh',
            right: '5em'
        }
    });
}
