import React, { useState, useEffect } from "react";
import { Loading } from "../../components/Loading/Loading";
import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from "../../components/Messages/messages";
import axios from "axios";
import { isAuthenticated } from "../../components/Auth/auth";
import "./Home.css";
// import advancedComplexityTable from './advancedComplexityTable.js';
import advancedInputWeights from "./advancedInputWeights.js";
import partDensities from "./partDensities.js";

export const Home = (props) => {
  const [loading, setLoading] = useState(false);
  const [showAdvancedFields, setAdvancedFieldsVisibility] = useState(false);
  const [showGeneralFields, setGeneralFieldsVisibility] = useState(false);
  const [selectedImageKey, setImageKey] = useState(false);

  const [imageComplexityMap] = useState([
    3.4, 3.6, 3.8, 3.8, 3.4, 3.6, 3.6, 3.8, 1.2, 2.8, 3.8, 1, 1, 1, 1, 1.2, 1,
    5, 1.2, 5.4, 1, 2.2, 2.2, 2.2, 1.2, 1.2, 1.2, 1.4, 1.2, 3.4, 2.6, 4.6,
  ]);
  const [selectedComplexity, setComplexity] = useState("1");
  const [selectedUserType, setUserType] = useState("");
  const [selectedOption, setSelectedOption] = useState("weight");

  const [data, setData] = useState({
    userType: "",
    partNumber: "",
    partMaterial: "A-356",
    grade: "A",
    partClass: "1",
    partQuantity: "0",
    length: "0",
    width: "0",
    height: "0",
    partDetail: "weight",
    partWeight: "0",
    partVolume: "0",
    surfaceArea: "0",
    partComplexity: "1",
    partEnvelopeVolume: "0",
    numPartingLines: "0",
    numContourLines: "0",
    numPullCores: "0",
    numSolubleCores: "0",
    pullCoreVolumes: [],
    solubleCoreVolumes: [],
  });

  const {
    userType,
    partNumber,
    partMaterial,
    grade,
    partClass,
    partQuantity,
    length,
    width,
    height,
    partDetail,
    partWeight,
    partVolume,
    surfaceArea,
    partComplexity,
    partEnvelopeVolume,
    numPartingLines,
    numContourLines,
    numPullCores,
    numSolubleCores,
    pullCoreVolumes,
    solubleCoreVolumes,
  } = data;

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdvancedUserButtonClick = () => {
    setGeneralFieldsVisibility(false);
    setAdvancedFieldsVisibility(!showAdvancedFields);
  };

  const handleGeneralUserButtonClick = () => {
    setAdvancedFieldsVisibility(false);
    setGeneralFieldsVisibility(!showGeneralFields);
  };

  const handleImageSelection = (index) => {
    setUserType("General");
    // setData({ ...data, userType: 'General' });
    if (index === selectedImageKey) {
      // Deselecting the image, set selectedImageKey to null and complexity to 1
      setImageKey(null);
      setComplexity("1");
      setData({ ...data, partComplexity: "1" });
    } else {
      // Selecting a new image, update selectedImageKey and complexity
      setImageKey(index);
      // Check if the complexity map is defined for the selected image
      if (imageComplexityMap[index] !== undefined) {
        setComplexity(imageComplexityMap[index].toString());
        setData({
          ...data,
          partComplexity: imageComplexityMap[index].toString(),
        });
      } else {
        // If not defined, set the complexity to '1'
        setComplexity("1");
        setData({ ...data, partComplexity: "1" });
      }
    }
    /*
    setImageKey(index === selectedImageKey ? null : index);
    setComplexity(imageComplexityMap[index].toString());
    */
  };

  const handleComplexityUpdate = () => {
    setUserType("Advanced");
    // setData({ ...data, userType: 'Advanced' });
    // Get the user inputs for partingPlanes, contourPlanes, pullCores, and solubleCores
    // const inputPartingPlanes = parseInt(data.numPartingLines);
    // const inputContourPlanes = parseInt(data.numContourLines);
    // const inputPullCores = parseInt(data.numPullCores);
    // const inputSolubleCores = parseInt(data.numSolubleCores);

    const inputPartingPlanes = isNaN(parseInt(data.numPartingLines))
      ? 0
      : parseInt(data.numPartingLines);
    const inputContourPlanes = isNaN(parseInt(data.numContourLines))
      ? 0
      : parseInt(data.numContourLines);
    const inputPullCores = isNaN(parseInt(data.numPullCores))
      ? 0
      : parseInt(data.numPullCores);
    const inputSolubleCores = isNaN(parseInt(data.numSolubleCores))
      ? 0
      : parseInt(data.numSolubleCores);

    /*
    // Find a matching entry in the complexityLookup array based on user inputs
    const matchingEntry = advancedComplexityTable.find((entry) => {
      return (
        entry.partingPlanes === inputPartingPlanes &&
        entry.contourPlanes === inputContourPlanes &&
        entry.pullCores === inputPullCores &&
        entry.solubleCores === inputSolubleCores
      );
    });

    // If a matching entry is found, update the complexity with the corresponding value
    if (matchingEntry) {
      // console.log("Found a matching entry in the advanced user complexity lookup");
      // console.log(matchingEntry);
      setComplexity(matchingEntry.complexity);
      setData({ ...data, partComplexity: matchingEntry.complexity });
      SuccessMessage('Successfully updated part complexity.');
    } else {
      // If no matching entry is found, set the complexity to the default value '1'
      // console.log("Couldn't find a matching entry in the advanced user complexity lookup");
      setComplexity('1');
      setData({ ...data, partComplexity: '1' });
      WarningMessage('Invalid combination of entries - setting complexity to default value.');
    }
    */

    if (
      inputPartingPlanes === 0 &&
      inputContourPlanes === 0 &&
      inputPullCores === 0 &&
      inputSolubleCores === 0
    ) {
      WarningMessage(
        "You must specify number of parting lines to update complexity."
      );
      return;
    }

    var complexity = 1;
    if (
      inputPartingPlanes === 1 &&
      inputContourPlanes === 0 &&
      inputPullCores === 0 &&
      inputSolubleCores === 0
    ) {
      setComplexity("1");
      setData({ ...data, partComplexity: "1" });
    } else {
      complexity +=
        inputPartingPlanes * advancedInputWeights.partingPlaneWeightage;
      complexity +=
        inputContourPlanes * advancedInputWeights.contourPlaneWeightage;
      complexity += inputPullCores * advancedInputWeights.pullCoreWeightage;
      complexity +=
        inputSolubleCores * advancedInputWeights.solubleCoreWeightage;
      complexity = Math.round((complexity + Number.EPSILON) * 100) / 100;
      setComplexity(complexity);
      setData({ ...data, partComplexity: complexity });
    }
    SuccessMessage("Successfully updated part complexity.");

    setShowPullCoreInput(true);
    // Parse the value from the input field to determine the number of pull cores entered
    const numEntered = parseInt(data.numPullCores, 10);
    if (!isNaN(numEntered) && numEntered >= 0) {
      setNumPullCoresEntered(numEntered);
    }
    /*
    else {
      // Handle invalid input
      alert("Please enter a valid number of pull cores.");
    }
    */

    setShowSolubleCoreInput(true);
    // Parse the value from the input field to determine the number of soluble cores entered
    const numScEntered = parseInt(data.numSolubleCores, 10);
    if (!isNaN(numScEntered) && numScEntered >= 0) {
      setNumSolubleCoresEntered(numScEntered);
    }
    /*
    else {
      // Handle invalid input
      alert("Please enter a valid number of soluble cores.");
    }
    */
  };

  useEffect(() => {
    setData({ ...data, userType: selectedUserType });
  }, [selectedUserType]);

  /*
  useEffect(() => {
    console.log('Selected image key: ' + selectedImageKey);
    console.log('Complexity: ' + selectedComplexity);
  }, [selectedImageKey, imageComplexityMap]);
  

  const handleComplexityUpdate = () => {
      if(selectedImageKey !== null) {
        setComplexity(imageComplexityMap[selectedImageKey].toString());
      }
  };

  const handleImageSelection = (key) => {    
    setImageKey(key);
    let images = document.querySelectorAll('.image-item');
    images.forEach((imageObject) => {
      if(imageObject.classList.contains('highlighted')) imageObject.classList.remove('highlighted');
    });
    document.getElementById(`part-img-${key}`).classList.add('highlighted');
  };

  useEffect(() => {
    console.log('Selected image index: ' + selectedImageKey);
    // document.querySelector(`#part-img-${selectedImageKey}`).classList.add('highlighted');
  }, [selectedImageKey]);
  */

  const [numPullCoresEntered, setNumPullCoresEntered] = useState(null); // Number of pull cores entered
  const [pullCoreBoxVolume, setPullCoreBoxVolume] = useState(""); // Input field for pull core box volume
  const [enteredPullCoreVolumes, setPullCoreVolumes] = useState([]); // Array to store entered pull core volumes
  const [showPullCoreInput, setShowPullCoreInput] = useState(false);

  const [numSolubleCoresEntered, setNumSolubleCoresEntered] = useState(null); // Number of soluble cores entered
  const [solubleCoreBoxVolume, setSolubleCoreBoxVolume] = useState(""); // Input field for soluble core box volume
  const [enteredSolubleCoreVolumes, setSolubleCoreVolumes] = useState([]); // Array to store entered soluble core volumes
  const [showSolubleCoreInput, setShowSolubleCoreInput] = useState(false);

  const handleAddPullCoreBoxVolume = () => {
    if (pullCoreBoxVolume.trim() !== "") {
      setPullCoreVolumes([...enteredPullCoreVolumes, pullCoreBoxVolume]);
      setData({
        ...data,
        pullCoreVolumes: [...enteredPullCoreVolumes, pullCoreBoxVolume],
      });
      setPullCoreBoxVolume(""); // Clear the input field
    } else {
      // Handle empty input
      WarningMessage("Please enter a pull core box volume.");
    }
  };

  const handleRemovePullCore = () => {
    if (enteredPullCoreVolumes.length > 0) {
      const updatedPullCoreVolumes = [...enteredPullCoreVolumes];
      updatedPullCoreVolumes.pop(); // Remove the last entry
      setPullCoreVolumes(updatedPullCoreVolumes);
      setData({ ...data, pullCoreVolumes: updatedPullCoreVolumes });
    }
  };

  const handleAddSolubleCoreBoxVolume = () => {
    if (solubleCoreBoxVolume.trim() !== "") {
      setSolubleCoreVolumes([
        ...enteredSolubleCoreVolumes,
        solubleCoreBoxVolume,
      ]);
      setData({
        ...data,
        solubleCoreVolumes: [
          ...enteredSolubleCoreVolumes,
          solubleCoreBoxVolume,
        ],
      });
      setSolubleCoreBoxVolume(""); // Clear the input field
    } else {
      // Handle empty input
      WarningMessage("Please enter a soluble core box volume.");
    }
  };

  const handleRemoveSolubleCore = () => {
    if (enteredSolubleCoreVolumes.length > 0) {
      const updatedSolubleCoreVolumes = [...enteredSolubleCoreVolumes];
      updatedSolubleCoreVolumes.pop(); // Remove the last entry
      setSolubleCoreVolumes(updatedSolubleCoreVolumes);
      setData({ ...data, solubleCoreVolumes: updatedSolubleCoreVolumes });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (isAuthenticated()) {
      setLoading(true);

      // console.log("pullCoreVolumes:", data.pullCoreVolumes);
      // console.log("solubleCoreVolumes:", data.solubleCoreVolumes);

      var partDensity = partDensities[data.partMaterial];
      // console.log("Part density -> ", partDensity);

      let partBoxVolume = data.length * data.width * data.height;
      // let metalDensity = 0.101;
      // let partVolume = partWeight / metalDensity;
      let partVolume = partWeight / partDensity;
      // console.log("Part box volume -> ", partBoxVolume);
      // console.log("Part volume -> ", partVolume);
      // console.log("Part envelope volume -> ", partEnvelopeVolume);

      if (partBoxVolume <= partEnvelopeVolume) {
        WarningMessage(
          "Invalid entries. REQUIRED: Part envelope volume must be less than box volume."
        );
        setLoading(false);
      } else if (partBoxVolume <= partVolume) {
        WarningMessage(
          "Invalid entries. REQUIRED: Part volume must be less than box volume."
        );
        setLoading(false);
      } else if (partEnvelopeVolume <= partVolume) {
        WarningMessage(
          "Invalid entries. REQUIRED: Part volume must be less than part envelope volume."
        );
        setLoading(false);
      } else {
        axios
          .post(
            // "http://localhost:8000/api/calculations/create",
            "https://api.castingcostadvisor.com/api/calculations/create",
            {
              userType,
              partNumber,
              partMaterial,
              grade,
              partClass,
              partQuantity,
              length,
              width,
              height,
              partDetail,
              partWeight,
              partVolume,
              surfaceArea,
              partComplexity,
              partEnvelopeVolume,
              numPartingLines,
              numContourLines,
              numPullCores,
              numSolubleCores,
              pullCoreVolumes,
              solubleCoreVolumes,
            },
            {
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              SuccessMessage(res.data.successMessage);
              props.history.push("/results");
            } else {
              ErrorMessage(res.data.errorMessage);
            }
          });
      }
    } else {
      WarningMessage(
        "Please log in to run cost calculations & save data to the backend."
      );
    }
  };

  const imageFilenames = [
    "image1.png",
    "image2.png",
    "image3.png",
    "image4.png",
    "image5.png",
    "image6.png",
    "image7.png",
    "image8.png",
    "image9.png",
    "image10.png",
    "image11.png",
    "image12.png",
    "image13.png",
    "image14.png",
    "image15.png",
    "image16.png",
    "image17.png",
    "image18.png",
    "image19.png",
    "image20.png",
    "image21.png",
    "image22.png",
    "image23.png",
    "image24.png",
    "image25.png",
    "image26.png",
    "image27.png",
    "image28.png",
    "image29.png",
    "image30.png",
    "image31.png",
    "image32.png",
  ];

  return (
    <div className="home">
      <form onSubmit={submitHandler}>
        <h4 className="display-3 text-center text-primary title">
          <b>COST ADVISOR FOR INVESTMENT CASTINGS</b>
        </h4>
        <br />
        <div className="form-floating">
          <input
            name="partNumber"
            type="text"
            className="form-control"
            required
            onChange={handleChange}
            id="floatingPassword"
            placeholder="partNumber"
          />
          <label for="floatingPassword">Part Number / Name</label>
        </div>
        <br />
        <div>
          <h5>Materials Section:</h5>
          <label className="label-styles" for="partMaterial">
            Part Material
          </label>
          <select
            name="partMaterial"
            className="form-control"
            required
            id="partMaterial"
            onChange={handleChange}
            placeholder="Part Material"
          >
            <option value="A-356" selected>
              {" "}
              A-356{" "}
            </option>
            <option value="A-357"> A-357 </option>
            <option value="A-148"> A-148 </option>
          </select>
          <br />
          <label className="label-styles" for="grade">
            Grade
          </label>
          <select
            name="grade"
            className="form-control"
            required
            id="grade"
            onChange={handleChange}
            placeholder="Grade"
          >
            <option value="A" selected>
              {" "}
              A{" "}
            </option>
            <option value="B"> B </option>
            <option value="C"> C </option>
            <option value="D"> D </option>
          </select>
          <br />
          <label className="label-styles" for="class">
            Class
          </label>
          <select
            name="partClass"
            className="form-control"
            required
            id="class"
            onChange={handleChange}
            placeholder="Class"
          >
            <option value="1" selected>
              {" "}
              1{" "}
            </option>
            <option value="2"> 2 </option>
            <option value="3"> 3 </option>
            <option value="4"> 4 </option>
          </select>
          <br />

          <div className="row mt-4">
            <h5>Enter part complexity:</h5>
          </div>

          <div className="form-floating">
            <input
              name="partComplexity"
              type="text"
              className="form-control"
              id="partComplexity"
              value={selectedComplexity}
              placeholder="partComplexity"
              required
              disabled
            />
            <label for="partComplexity">Part Complexity</label>
          </div>

          <div className="row my-3">
            <div className="col-sm">
              <button
                type="button"
                onClick={handleGeneralUserButtonClick}
                className="btn btn-primary w-100"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                General User
              </button>
            </div>
            <div className="col-sm align-self-center">
              <button
                type="button"
                onClick={handleAdvancedUserButtonClick}
                className="btn btn-primary w-100"
              >
                Advanced User
              </button>
            </div>
            <div className="col-sm">
              <a
                href="/assets/aluminum-part-complexity-lib.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="btn btn-primary w-100">
                  More examples
                </button>
              </a>
            </div>
          </div>
          <br />

          <input
            name="userType"
            type="hidden"
            className="form-control"
            id="userType"
            value={selectedUserType}
          />

          <div style={{ display: showAdvancedFields ? "block" : "none" }}>
            <div className="form-floating mb-3">
              <input
                name="numPartingLines"
                type="text"
                className="form-control"
                onChange={handleChange}
                id="partingLines"
                placeholder="No. of parting lines"
              />
              <label for="partingLines">No. of parting lines</label>
            </div>
            <div className="form-floating mb-3">
              <input
                name="numContourLines"
                type="text"
                className="form-control"
                onChange={handleChange}
                id="contourLines"
                placeholder="No. of contour lines"
              />
              <label for="contourLines">No. of contour lines</label>
            </div>
            <div className="form-floating mb-3">
              <input
                name="numPullCores"
                type="text"
                className="form-control"
                onChange={handleChange}
                id="pullCores"
                placeholder="No. of pull cores"
              />
              <label for="pullCores">No. of pull cores</label>
            </div>
            <div className="form-floating mb-3">
              <input
                name="numSolubleCores"
                type="text"
                className="form-control"
                onChange={handleChange}
                id="solubleCores"
                placeholder="No. of soluble cores"
              />
              <label for="solubleCores">No. of soluble cores</label>
            </div>
            <div className="form-floating mb-5">
              <button
                type="button"
                id="updateComplexity"
                className="btn btn-primary w-100"
                onClick={handleComplexityUpdate}
              >
                Update complexity
              </button>
            </div>

            <div>
              {numPullCoresEntered !== null && numPullCoresEntered > 0 && (
                <div>
                  <h3>Enter Pull Core Box Volumes</h3>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="pullCoreBoxVolume"
                      id="pullCoreBoxVolume"
                      className="form-control"
                      placeholder="Pull core box volume"
                      value={pullCoreBoxVolume}
                      onChange={(e) => setPullCoreBoxVolume(e.target.value)}
                      disabled={
                        enteredPullCoreVolumes.length >= numPullCoresEntered
                      }
                    />
                    <label for="pullCoreBoxVolume">Pull Core Box Volume</label>
                  </div>
                  <div className="form-floating mb-5">
                    <button
                      type="button"
                      id="addPullCoreBoxVolume"
                      onClick={handleAddPullCoreBoxVolume}
                      className="btn btn-primary w-100"
                      disabled={
                        enteredPullCoreVolumes.length >= numPullCoresEntered
                      }
                    >
                      Add Pull Core Box Volume
                    </button>
                  </div>
                </div>
              )}
              {numPullCoresEntered !== null && numPullCoresEntered > 0 && (
                <div className="mb-3">
                  <h3>Pull Core Box Volumes</h3>
                  {enteredPullCoreVolumes.length > 0 ? (
                    <div className="row row-cols-1 row-cols-md-3">
                      {enteredPullCoreVolumes.map((volume, index) => (
                        <div className="col mb-3" key={index}>
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <p className="card-text">
                                    <b>Pull Core Volume {index + 1}:</b>{" "}
                                    {volume}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-white">
                      No pull core box volumes specified.
                    </p>
                  )}
                  <div className="form-floating mb-5">
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      onClick={handleRemovePullCore}
                      disabled={enteredPullCoreVolumes.length === 0}
                    >
                      Remove Most Recent Entry
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div>
              {numSolubleCoresEntered !== null &&
                numSolubleCoresEntered > 0 && (
                  <div>
                    <h3>Enter Soluble Core Box Volumes</h3>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="solubleCoreBoxVolume"
                        id="solubleCoreBoxVolume"
                        className="form-control"
                        placeholder="Soluble core box volume"
                        value={solubleCoreBoxVolume}
                        onChange={(e) =>
                          setSolubleCoreBoxVolume(e.target.value)
                        }
                        disabled={
                          enteredSolubleCoreVolumes.length >=
                          numSolubleCoresEntered
                        }
                      />
                      <label for="solubleCoreBoxVolume">
                        Soluble Core Box Volume
                      </label>
                    </div>
                    <div className="form-floating mb-5">
                      <button
                        type="button"
                        id="addSolubleCoreBoxVolume"
                        onClick={handleAddSolubleCoreBoxVolume}
                        className="btn btn-primary w-100"
                        disabled={
                          enteredSolubleCoreVolumes.length >=
                          numSolubleCoresEntered
                        }
                      >
                        Add Soluble Core Box Volume
                      </button>
                    </div>
                  </div>
                )}
              {numSolubleCoresEntered !== null &&
                numSolubleCoresEntered > 0 && (
                  <div className="mb-3">
                    <h3>Soluble Core Box Volumes</h3>
                    {enteredSolubleCoreVolumes.length > 0 ? (
                      <div className="row row-cols-1 row-cols-md-3">
                        {enteredSolubleCoreVolumes.map((volume, index) => (
                          <div className="col mb-3" key={index}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <p className="card-text">
                                      <b>Soluble Core Volume {index + 1}:</b>{" "}
                                      {volume}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-white">
                        No soluble core box volumes specified.
                      </p>
                    )}
                    <div className="form-floating mb-5">
                      <button
                        type="button"
                        className="btn btn-danger w-100"
                        onClick={handleRemoveSolubleCore}
                        disabled={enteredSolubleCoreVolumes.length === 0}
                      >
                        Remove Most Recent Entry
                      </button>
                    </div>
                  </div>
                )}
            </div>

            <input
              name="pullCoreVolumes"
              type="hidden"
              className="form-control"
              id="pullCoreVolumes"
              value={enteredPullCoreVolumes}
            />
            <input
              name="solubleCoreVolumes"
              type="hidden"
              className="form-control"
              id="solubleCoreVolumes"
              value={enteredSolubleCoreVolumes}
            />
          </div>

          <div style={{ display: showGeneralFields ? "block" : "none" }}>
            <div className="my-container mb-3">
              <div className="images-wrapper mb-2">
                {imageFilenames.map((filename, index) => (
                  <div
                    key={index}
                    id={`part-img-${index + 1}`}
                    className={`image-item ${
                      selectedImageKey === index ? "highlighted" : ""
                    }`}
                    onClick={() => handleImageSelection(index)}
                  >
                    <img
                      src={`assets/part-images/${filename}`}
                      alt={`${filename}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-floating">
            <input
              name="partQuantity"
              type="text"
              className="form-control"
              required
              onChange={handleChange}
              id="partQuant"
              placeholder="Part Quantity"
            />
            <label for="partQuant">Part Quantity</label>
          </div>
        </div>
        <br />

        <div>
          <div className="row my-3 mt-4">
            <div className="col-md-6">
              <h5>Enter box dimensions:</h5>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a href="/assets/box-dims.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100">
                  How to enter Box Dimensions
                </button>
              </a>
            </div>
          </div>
          <div className="form-floating mb-3">
            <input
              name="length"
              type="text"
              className="form-control"
              required
              onChange={handleChange}
              id="partLen"
              placeholder="Length (inches)"
            />
            <label for="partLen">Length (inches)</label>
          </div>
          <div className="form-floating mb-3">
            <input
              name="width"
              type="text"
              className="form-control"
              required
              onChange={handleChange}
              id="partWid"
              placeholder="Width (inches)"
            />
            <label for="partWid">Width (inches)</label>
          </div>
          <div className="form-floating mb-5">
            <input
              name="height"
              type="text"
              className="form-control"
              required
              onChange={handleChange}
              id="partHeight"
              placeholder="Height (inches)"
            />
            <label for="partHeight">Height (inches)</label>
          </div>

          <label className="label-styles" htmlFor="partDetail">
            Select an option from below:
          </label>
          <select
            name="partDetail"
            className="form-control"
            required
            id="partDetail"
            onChange={(e) => setSelectedOption(e.target.value)}
            placeholder="Part Detail"
          >
            <option value="weight" selected>
              {" "}
              Enter Part Weight{" "}
            </option>
            <option value="volume"> Enter Part Volume </option>
          </select>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input
                  name="partWeight"
                  type="text"
                  className="form-control"
                  required
                  disabled={selectedOption !== "weight"}
                  onChange={handleChange}
                  id="partWeight"
                  placeholder="Enter Part Weight (lbs)"
                />
                <label for="partWeight">Part Weight (lbs)</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a
                href="/assets/part-weight-tutorial.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Part Weight
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input
                  name="partVolume"
                  type="text"
                  className="form-control"
                  required
                  disabled={selectedOption !== "volume"}
                  onChange={handleChange}
                  id="partVol"
                  placeholder="Part Volume"
                />
                <label for="partVol">Part Volume (Cu. Inch)</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a
                href="/assets/part-volume-tutorial.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Part Volume
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input
                  name="surfaceArea"
                  type="text"
                  className="form-control"
                  required
                  onChange={handleChange}
                  id="area"
                  placeholder="Surface Area (Sq. Inch)"
                />
                <label for="area">Surface Area (Sq. Inch)</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a
                href="/assets/surface-area.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Surface Area
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input
                  name="partEnvelopeVolume"
                  type="text"
                  className="form-control"
                  required
                  onChange={handleChange}
                  id="enVol"
                  placeholder="Part Envelope Volume"
                />
                <label for="enVol">Part Envelope Volume</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a
                href="/assets/part-envelope-volume.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Part Env. Vol.
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mt-5">
            <div className="col-md-6 align-self-center">
              <button
                disabled={false}
                type="submit"
                className="btn btn-primary w-100"
              >
                {loading ? <Loading /> : <span>Submit</span>}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
